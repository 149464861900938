import React, { useState } from "react";
import IProps from "./types";
import Topbar from "./components/Topbar/Topbar";
import { FlexContainer } from "./styles";
import BottomContainer from "./components/BottomContainer/BottomContainer";
import FileChoose from "./components/FileChoose/FileChoose";
import ReplaceFile from "./components/ReplaceFile/ReplaceFile";
import UploadSuccess from "./components/UploadSuccess/UploadSuccess";

const TermsAndConditionsView = (props: IProps) => {
  const [inputFile, setInputFile] = useState<any>(null);

  const onChangeFile = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setInputFile(file);
  };

  const onSubmitFile = () => {
    if (inputFile) {
      props.onUploadAsset(inputFile);
    }
  };

  return (
    <FlexContainer>
      <Topbar />
      <BottomContainer>
        {!inputFile ? (
          <FileChoose onChangeFile={onChangeFile} />
        ) : (
          !props.successUpload && (
            <ReplaceFile
              filename={inputFile.name}
              onChangeFile={onChangeFile}
              onAcceptTerms={onSubmitFile}
            />
          )
        )}
        {props.successUpload && <UploadSuccess />}
      </BottomContainer>
    </FlexContainer>
  );
};

export default TermsAndConditionsView;
