import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import Lottie, { AnimationItem } from "lottie-web";
import { v4 as uuidv4 } from "uuid";
import TextVariableType from "enums/textVariable.enum";
import { ILayer } from "common/interfaces/layer.interface";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import { SOCIAL_MEDIA } from "common/constants/social_media.constants";
import audioFadeOut from "helper/audioFadeOut.helper";
import useAudio from "customHooks/useAudio.hook";
import { Howl } from "howler";
import { AnimationContainer, AnimationLayer, VideoComponent } from "./styles";
import { IProps } from "./types";

/* eslint-disable react-hooks/exhaustive-deps */

const Video: FunctionComponent<IProps> = (props: IProps) => {
  const [animations, setAnimations] = useState<AnimationItem[]>([]);
  const [id] = useState(uuidv4());
  const [muteValue, toggleMuteMethod, audioElement]: (
    | boolean
    | (() => void)
    | Howl
  )[] = useAudio(props.audio);
  const mute = muteValue as boolean;
  const toggleMute = toggleMuteMethod as () => void;
  const audio = audioElement as Howl;
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (animations.length) {
      const found = animations.find(
        (animation: AnimationItem) => animation.name === `texts_${id}`
      );
      if (found) {
        found.addEventListener("complete", () => {
          if (props.onComplete) {
            props.onComplete();
            if (videoRef.current) {
              videoRef.current.pause();
            }
          }
        });
      }
    }
  }, [animations]);

  useEffect(() => {
    if (animations.length > 0) {
      animations.forEach((animation: AnimationItem) => {
        Lottie.destroy(animation.name);
      });
    }
    let animationsLayers: AnimationItem[] | null = null;
    animationsLayers = props.layers.map((layer: ILayer) => {
      return Lottie.loadAnimation({
        container: document.getElementById(
          `${layer.layer.key}_${id}`
        ) as HTMLElement,
        renderer: "svg",
        loop: false,
        animationData: layer.dataPath,
        name: `${layer.layer.key}_${id}`,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid meet",
          progressiveLoad: true,
        },
      });
    });

    animationsLayers.forEach((animation: AnimationItem) => {
      if (props.segments?.length) {
        animation.goToAndStop(props.segments[1], true);
      }
    });

    animationsLayers.forEach((animation: AnimationItem) => animation.resize());

    const resize = () => {
      if (animationsLayers) {
        animationsLayers.forEach((animation: AnimationItem) =>
          animation.resize()
        );
      }
    };

    window.addEventListener("resize", resize);

    setAnimations(animationsLayers);

    return () => {
      animations.forEach((animation: AnimationItem) => {
        Lottie.destroy(animation.name);
      });
    };
  }, [props.layers]);

  const onClickCta = (url: string) => {
    if (props.onUpdateCTAClicked) {
      props.onUpdateCTAClicked();
    }
    props.onClickEndCta(url);
  };

  useEffect(() => {
    if (animations.length) {
      props.textVariables
        .filter(
          (textVariable: ITextVariable) =>
            textVariable.type === TextVariableType.CTA
        )
        .forEach((textVariable: ITextVariable) => {
          // TODO FIXME @GuidoG try to fix this, using refs?
          const hoverLayers = document.querySelectorAll(
            `${textVariable.name}_in path`
          )[0] as HTMLElement;
          /*
            This is done with this stack overflow 
            https://stackoverflow.com/questions/6991494/javascript-getelementbyid-based-on-a-partial-string
            Basically this finds and if finishing with our textvariable number => text00    
          */
          const scaleLayer = document.querySelectorAll(
            `[id$="${textVariable.name.split("_")[1]}"] path`
          );

          if (hoverLayers) {
            hoverLayers.addEventListener("mouseover", () => {
              hoverLayers.style.cursor = "pointer";
              scaleLayer.forEach((element: Element) => {
                const colorSelector = document.querySelector(
                  ":root"
                ) as HTMLElement;
                let rgb = "";
                if (element.getAttribute("fill")) {
                  rgb = element.getAttribute("fill") as string;
                  if (colorSelector) {
                    colorSelector.style.setProperty("--color--cta", rgb);
                    colorSelector.style.setProperty("--color--cta--hover", rgb);
                    element.removeAttribute("class");
                    if (rgb === "rgb(0,0,0)") {
                      element.setAttribute("class", "button_color_hover_black");
                    } else {
                      element.setAttribute("class", "button_color_hover");
                    }
                  }
                }
              });
            });
            hoverLayers.addEventListener("mouseout", () => {
              scaleLayer.forEach((element: Element) => {
                element.removeAttribute("class");
                element.setAttribute("class", "button_color");
              });
            });
            hoverLayers.addEventListener("click", () =>
              onClickCta(textVariable.ctaLink)
            );
          }
        });
    }
  }, [animations, props.textVariables]);

  useEffect(() => {
    if (animations.length > 0) {
      // TODO FIXME @GuidoG try to fix this, using refs?
      const hoverFacebook = document.querySelectorAll(
        `[id*="sm_${SOCIAL_MEDIA.facebook}_in"] path`
      )[0] as HTMLElement;

      const hoverLinkedin = document.querySelectorAll(
        `[id*="sm_${SOCIAL_MEDIA.linkedin}_in"] path`
      )[0] as HTMLElement;

      const hoverTwitter = document.querySelectorAll(
        `[id*="sm_${SOCIAL_MEDIA.twitter}_in"] path`
      )[0] as HTMLElement;
      /*
            This is done with this stack overflow 
            https://stackoverflow.com/questions/6991494/javascript-getelementbyid-based-on-a-partial-string
            Basically this finds and if finishing with our textvariable number => text00    
          */
      if (hoverFacebook) {
        hoverFacebook.addEventListener("mouseover", () => {
          hoverFacebook.style.cursor = "pointer";
        });
        hoverFacebook.addEventListener("click", () => {
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
            "_blank"
          );
        });
      }

      if (hoverLinkedin) {
        hoverLinkedin.addEventListener("mouseover", () => {
          hoverLinkedin.style.cursor = "pointer";
        });
        hoverLinkedin.addEventListener("click", () => {
          window.open(
            `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
            "_blank"
          );
        });
      }
      if (hoverTwitter) {
        hoverTwitter.addEventListener("mouseover", () => {
          hoverTwitter.style.cursor = "pointer";
        });
        hoverTwitter.addEventListener("click", () => {
          window.open(
            `https://twitter.com/intent/tweet?url=${window.location.href}`,
            "_blank"
          );
        });
      }
    }
  }, [animations]);

  useEffect(() => {
    let metricsStarted = false;
    let secondToSave = 1;
    if (animations.length) {
      const textsLayer = animations.find(
        (animation: AnimationItem) => animation.name === `texts_${id}`
      );

      if (textsLayer) {
        textsLayer.addEventListener("enterFrame", () => {
          const currentSecond = textsLayer.currentFrame / textsLayer.frameRate;
          if (textsLayer.currentFrame === 1) {
            metricsStarted = false;
            secondToSave = 1;
          }

          if (
            props.onCreateMetric &&
            !metricsStarted &&
            Math.floor(currentSecond) === 0
          ) {
            metricsStarted = true;
            props.onCreateMetric();
          }

          if (
            props.onUpdateWatchedTime &&
            Math.floor(currentSecond) >= secondToSave
          ) {
            if (videoRef.current) {
              if (currentSecond > videoRef.current.currentTime + 0.2) {
                videoRef.current.currentTime = currentSecond;
              }
            }
            props.onUpdateWatchedTime(secondToSave);
            // eslint-disable-next-line no-console
            console.log("SECOND: ", secondToSave);
            secondToSave++;
          }
        });
      }
    }
  }, [animations]);

  useEffect(() => {
    if (!mute || props.toggleAudio) {
      toggleMute();
    }
  }, [props.toggleAudio]);

  useEffect(() => {
    if (props.replay) {
      animations.forEach((animation: AnimationItem) => {
        animation.goToAndPlay(1, true);
        audio.pause();
        audio.seek(0);
        audio.play();
      });
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    }
  }, [props.replay, audio, animations]);

  useEffect(() => {
    if (props.duration) {
      setTimeout(() => audioFadeOut(audio, 200), props.duration * 1000 - 2000);
    }
  }, [props.replay]);

  return (
    <AnimationContainer backgroundColor={props.backgroundColor}>
      {props.backgroundVideo && (
        <VideoComponent
          src={props.backgroundVideo}
          autoPlay
          muted
          playsInline
          ref={videoRef}
        />
      )}
      <AnimationLayer id={`miscellaneous_${id}`} />
      <AnimationLayer id={`objects_${id}`} />
      <AnimationLayer id={`images_${id}`} />
      <AnimationLayer id={`texts_${id}`} />
      <AnimationLayer id={`interactions_${id}`} />
    </AnimationContainer>
  );
};

export default Video;
