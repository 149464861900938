import { ACCEPT_TYPES } from "common/constants/fileInputAcceptTypes.contants";
import React, { useRef } from "react";
import { Container, SubmitInput, Subtitle, Title } from "./styles";
import IProps from "./types";

const FileChoose = (props: IProps) => {
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const onClickButton = () => {
    hiddenInputRef.current?.click();
  };

  return (
    <Container>
      <Title>Campaign name</Title>
      <Subtitle>Upload your csv file to personalice ….</Subtitle>
      <SubmitInput onClick={onClickButton}>Choose file</SubmitInput>
      <input
        ref={hiddenInputRef}
        type="file"
        hidden
        onChange={(event) => props.onChangeFile(event)}
        accept={ACCEPT_TYPES.csv}
      />
    </Container>
  );
};

export default FileChoose;
