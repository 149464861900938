import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import history from "helper/history";
import assetReducer from "./asset.reducer";
import templateReducer from "./template.reducer";
import clientReducer from "./client.reducer";
import shareReducer from "./share.reducer";
import campaignReducer from "./campaigns.reducer";
import userReducer from "./user.reducer";
import metricReducer from "./metric.reducer";
import errorReducer from "./error.reducer";
import successReducer from "./success.reducer";
import textVariableReducer from "./text-variable.reducer";
import nearpodCharacterCampaignReducer from "./nearpod-character-campaign.reducer";
import wellnetEOYReducer from "./wellnetEOY.reducer";

const rootReducer = combineReducers({
  router: connectRouter(history),
  asset: assetReducer,
  template: templateReducer,
  client: clientReducer,
  share: shareReducer,
  campaign: campaignReducer,
  user: userReducer,
  metric: metricReducer,
  error: errorReducer,
  success: successReducer,
  textVariable: textVariableReducer,
  nearpodCharacterCampaign: nearpodCharacterCampaignReducer,
  wellnetEOY: wellnetEOYReducer,
});

export default rootReducer;
