import React, { ChangeEvent, useEffect, useState } from "react";
import BottomContainer from "views/TermsAndConditionsView/components/BottomContainer/BottomContainer";
import Topbar from "views/TermsAndConditionsView/components/Topbar/Topbar";
import { FlexContainer } from "views/TermsAndConditionsView/styles";
import { Container, InputText, SubmitInput, Subtitle, Title } from "./styles";
import IProps from "./types";

const TermsAndConditionsDownloadView = (props: IProps) => {
  const [inputText, setInputText] = useState("");
  const [disable, setDisable] = useState(true);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInputText(event?.target.value);
  };

  useEffect(() => {
    if (inputText.length >= 6) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [inputText]);

  const onSubmitDownload = () => {
    props.onSubmit(inputText);
  };

  return (
    <FlexContainer>
      <Topbar />
      <BottomContainer>
        <Container>
          <Title>Campaign name</Title>
          <Subtitle>
            Ya tenemos tu campaña lista, ingresa el código de verificación para
            descargarla
          </Subtitle>
          <InputText
            type="text"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeInput(event)
            }
          />
          <SubmitInput
            disable={disable}
            onClick={() => (disable ? null : onSubmitDownload())}
          >
            Download
          </SubmitInput>
        </Container>
      </BottomContainer>
    </FlexContainer>
  );
};

export default TermsAndConditionsDownloadView;
