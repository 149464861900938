import { PUBLIC_URL } from "configs/configs";
import React from "react";
import { Container, ImageSuccess, Subtitle, Title } from "./styles";

const UploadSuccess = () => {
  return (
    <Container>
      <Title>Campaign name</Title>
      <ImageSuccess src={`${PUBLIC_URL}/common/okHand.svg`} alt="ok hand" />
      <Subtitle>
        Tu Db fue enviada con éxito, a continuación recibirás un mail con el
        link de descarga de la campaña
      </Subtitle>
    </Container>
  );
};

export default UploadSuccess;
