import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, SwitchProps } from "react-router-dom";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import TemplateForm from "containers/TemplateForm/TemplateForm";
import PublicRoute from "containers/publicRoute/PublicRoute";
import PrivateRoute from "containers/privateRoute/PrivateRoute";
import ClientDashboardContainer from "containers/clientDashboard/clientDashboard";
import ShareCampaignDashboardContainer from "containers/shareCampaignDashboard/shareCampaignDashboardContainer";
import TemplateDashboardContainer from "containers/TemplatesDashboard/TemplatesDashboard";
import FormCampaignContainer from "containers/formCampaign/formCampaign";
import ImportCSVContainer from "containers/ImportCSV/ImportCSV";
import Login from "containers/Login/Login";
import { isUserLoggedSelector } from "redux/selectors/userLogged.selector";
import EditShareCampaignVariables from "containers/editShareCampaignVariables/editShareCampaignVariables";
import Register from "containers/Register/Register";
import UserDashboardContainer from "containers/UsersDashboard/UsersDashboard";
import CreateUserContainer from "containers/CreateUser/CreateUser";
import AddShareCampaignContainer from "containers/addShareCampaign/addShareCampaign";
import TemplatePreviewContainer from "containers/TemplatePreview/TemplatePreview";
import MetricsDashboardContainer from "containers/MetricsDashboard/MetricsDashboard";
import CreateClient from "containers/CreateClient/CreateClient";
import CampaignDashboardContainer from "containers/campaignDashboard/campaignDashboardContainer";
import NearpodCampEngage from "containers/NearpodCampEngage/NearpodCampEngage";
import NearpodCampEngageCustomers from "containers/NearpodCampEngageCustomers/NearpodCampEngageCustomers";
import ForgotPassword from "containers/ForgotPassword/ForgotPassword";
import ChangePassword from "containers/ChangePassword/ChangePassword";
import SharePlayer from "containers/sharePlayer/sharePlayer";
import SpartanFormTest from "containers/SpartanFormTest/SpartanFormTest";
import NotFoundPage from "containers/NotFoundPage/NotFoundPage";
import TextVariableDashboardContainer from "containers/textVariableDashboard/textVariableDashboard";
import EditTextVariable from "containers/editTextVariable/editTextVariable";
import VideoTest from "containers/VideoTest/VideoTest";
import { NODE_ENVIRONMENT } from "configs/configs";
import SpartanReferralCampaign from "containers/SpartanReferralCampaign/SpartanReferralCampaign";
import AssetDashboardContainer from "containers/AssetDashboard/assetDashboard";
import AssetForm from "containers/addAssetForm/AddAssetForm";
import StatsCampaignContainer from "containers/StatsCampaign/StatsCampaign";
import NearpodCampEngageCharacterCampaign from "containers/NearpodCampEngageCharacterCampaign/NearpodCampEngageCharacterCampaign";
import TermsAndConditions from "containers/TermsAndConditions/TermsAndConditions";
import TermsAndConditionsDownload from "containers/TermsAndConditionsDownload/TermsAndConditionsDownload";
import WellnetEOYCampaign from "containers/WellnetEOYCampaign/WellnetEOYCampaign";
import WellnetGeneralEOYCampaign from "containers/WellnetGeneralEOYCampaign/WellnetGeneralEOYCampaign";
import MetricsFormContainer from "containers/MetricsForm/MetricsForm";

const Application: FunctionComponent<SwitchProps> = () => {
  const isUserLogged = !!useSelector(isUserLoggedSelector);

  return (
    <>
      <Switch>
        <PublicRoute
          exact
          path="/share/:id"
          component={SharePlayer}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/policies/wellnet/terms-conditions/16889edd-e0d9-456c-a461-14fcfcdabd8d"
          component={TermsAndConditions}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/policies/wellnet/terms-conditions/16889edd-e0d9-456c-a461-14fcfcdabd8d/download"
          component={TermsAndConditionsDownload}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/share/:id"
          component={SharePlayer}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/camp-engage-intl/:id"
          component={SharePlayer}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/test-form/"
          component={SpartanFormTest}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/spartan-race-bring-a-friend/:id"
          component={VideoTest}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/spartan-race-bring-a-friend-ref/:id"
          component={SpartanReferralCampaign}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/latam-camp-engage-promo/:id"
          component={NearpodCampEngage}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/latam-camp-engage-promo-customers/:id"
          component={NearpodCampEngageCustomers}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/eoy_event/:id"
          component={WellnetEOYCampaign}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/eoy_2021/:id"
          component={WellnetGeneralEOYCampaign}
          restricted={false}
        />
        <PublicRoute exact path="/login" component={Login} restricted={false} />
        <PublicRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/change-password/:token"
          component={ChangePassword}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/register/:token"
          component={Register}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/campengage-createyouravatar"
          component={NearpodCampEngageCharacterCampaign}
          restricted={false}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          component={DashboardLayout}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/clients"
          component={ClientDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/assets"
          component={AssetDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/assets/add"
          component={AssetForm}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/clients/create"
          component={CreateClient}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/campaigns"
          component={CampaignDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/share-campaigns/:id"
          component={ShareCampaignDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/templates"
          component={TemplateDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/share-campaigns/edit/:id"
          component={EditShareCampaignVariables}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/text-variable/edit/:id"
          component={EditTextVariable}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          path="/dashboard/templates/create"
          component={TemplateForm}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/share-campaigns/add/:id"
          component={AddShareCampaignContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/templates/preview/:id"
          component={TemplatePreviewContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/users"
          component={UserDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/text-variables"
          component={TextVariableDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/users/create"
          component={CreateUserContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/campaign/create"
          component={FormCampaignContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/campaign/:campaignId/metrics"
          component={MetricsDashboardContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/campaign/:campaignId/metrics-form"
          component={MetricsFormContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute
          exact
          path="/dashboard/share-campaign/create/:id"
          component={ImportCSVContainer}
          isUserLogged={isUserLogged}
        />
        <PublicRoute
          exact
          path="/stats/:campaignId"
          component={StatsCampaignContainer}
          restricted={false}
        />
        <PublicRoute
          exact
          path="/404"
          component={NotFoundPage}
          restricted={false}
        />
        {NODE_ENVIRONMENT !== "production" ? (
          <Route path="/">
            <Redirect to="/dashboard" />
          </Route>
        ) : (
          <Route path="/">
            <Redirect to="/404" />
          </Route>
        )}
      </Switch>
    </>
  );
};

export default Application;
