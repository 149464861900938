import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import React from "react";
import BackgroundAnimation from "./bg.json";
import IProps from "./types";

const BackgroundAnim = (props: IProps) => {
  const setAnimStarted = () => {
    props.setAnimationStarted(true);
  };

  return (
    <LottiePlayer
      animationJsonPath={BackgroundAnimation}
      loop
      autoplay
      segments={[50, 239]}
      speed={0.5}
      setAnimationStarted={setAnimStarted}
    />
  );
};

export default BackgroundAnim;
